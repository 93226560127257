import {shouldPolyfill} from '@formatjs/intl-relativetimeformat/should-polyfill'

async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-relativetimeformat/polyfill')
  }

  if (Intl.RelativeTimeFormat.polyfilled) {
    switch (locale) {
      default:
        await import('@formatjs/intl-relativetimeformat/locale-data/en')
        break
      case 'es':
        await import('@formatjs/intl-relativetimeformat/locale-data/es')
        break
    }
  }
}

polyfill()
