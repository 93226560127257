// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-delete-tsx": () => import("./../../../src/pages/delete.tsx" /* webpackChunkName: "component---src-pages-delete-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-html-tsx": () => import("./../../../src/pages/privacy_policy.html.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-html-tsx" */)
}

